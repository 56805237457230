import './styles/style.css'

var ge1doot = ge1doot || {
  screen: {
    elem: null,
    callback: null,
    width: 0,
    height: 0,
    left: 0,
    top: 0,
    init: function (id, callback, initRes) {
      this.elem = document.getElementById(id)
      this.callback = callback || null
      window.addEventListener(
        'resize',
        function () {
          ge1doot.screen.resize()
        },
        false
      )
      initRes && this.resize()
    },
    resize: function () {
      var o = this.elem
      this.width = o.offsetWidth
      this.height = o.offsetHeight
      for (this.left = 0, this.top = 0; o != null; o = o.offsetParent) {
        this.left += o.offsetLeft
        this.top += o.offsetTop
      }
      this.callback && this.callback()
    },
  },
  drag: {
    screen: null,
    elem: null,
    x: 0,
    y: 0,
    xs: 0,
    ys: 0,
    bx: 0,
    by: 0,
    xp: 0,
    yp: 0,
    active: true,
    down: function (e, touch) {
      e.preventDefault()
      var pointer = touch ? e.touches[0] : e
      !touch && document.setCapture && document.setCapture()
      this.xp = this.xs = pointer.clientX - this.screen.left
      this.yp = this.ys = pointer.clientY - this.screen.top
      this.active = true
    },
    up: function (e, touch) {
      e.preventDefault()
      !touch && document.releaseCapture && document.releaseCapture()
      this.bx = this.x
      this.by = this.y
      this.active = false
    },
    move: function (e, touch) {
      e.preventDefault()
      if (this.active) {
        var pointer = touch ? e.touches[0] : e
        this.xp = pointer.clientX - this.screen.left
        this.yp = pointer.clientY - this.screen.top
        this.x = this.bx - (this.xp - this.xs)
        this.y = this.by - (this.yp - this.ys)
      }
    },
    init: function (screen) {
      var self = this
      this.screen = screen
      this.elem = screen.elem
      if ('ontouchstart' in window) {
        // touch
        this.elem.ontouchstart = function (e) {
          self.down(e, true)
        }
        this.elem.ontouchmove = function (e) {
          self.move(e, true)
        }
        this.elem.ontouchend = function (e) {
          self.up(e, true)
        }
        this.elem.ontouchcancel = function (e) {
          self.up(e, true)
        }
      }
      // mouse
      document.addEventListener(
        'mousedown',
        function (e) {
          self.down(e, false)
        },
        true
      )
      document.addEventListener(
        'mousemove',
        function (e) {
          self.move(e, false)
        },
        true
      )
      document.addEventListener(
        'mouseup',
        function (e) {
          self.up(e, false)
        },
        true
      )
    },
  },
  Ease: function (speed, val) {
    this.speed = speed
    this.target = val
    this.value = val
  },
}

ge1doot.Ease.prototype.ease = function (target) {
  this.value += (target - this.value) * this.speed
}
/* document.body.style.overflow = 'hidden' */
// Particle-based JS Fluid Simulation
// Adapted from Grant Kot visco-elastic Java implementation
// http://kotsoft.googlepages.com/Viscoelastic2.html

!(function () {
  'use strict'
  var gridResolution = 100
  var rad = 50
  var maxParticles = 1000

  /* ==== the RAM ==== */
  var nBytes = 8 * maxParticles
  var buffer = new ArrayBuffer(10 * nBytes)
  var particles_x = new Float64Array(buffer, 0 * nBytes, maxParticles)
  var particles_y = new Float64Array(buffer, 1 * nBytes, maxParticles)
  var particles_pprevx = new Float64Array(buffer, 2 * nBytes, maxParticles)
  var particles_pprevy = new Float64Array(buffer, 3 * nBytes, maxParticles)
  var particles_velx = new Float64Array(buffer, 4 * nBytes, maxParticles)
  var particles_vely = new Float64Array(buffer, 5 * nBytes, maxParticles)
  var particles_vxl = new Float64Array(buffer, 6 * nBytes, maxParticles)
  var particles_vyl = new Float64Array(buffer, 7 * nBytes, maxParticles)
  var particles_q = new Float64Array(buffer, 8 * nBytes, maxParticles)
  var grid = []
  var neighbors = []
  var nParticles = 0
  // ==== screen ====
  var screen = ge1doot.screen
  var pointer = ge1doot.drag
  screen.init('screen', function () {}, true)
  pointer.init(screen)
  var canvas = document.getElementById('canvas')
  var ctx = canvas.getContext('2d')
  canvas.width = screen.width
  canvas.height = screen.height
  var nbX = Math.round(screen.width / gridResolution) + 1
  var nbY = Math.round(screen.height / gridResolution) + 1
  // ==== cell constructor ====
  function Cell() {
    this.len = 0
    this.neighborsParticles = []
  }
  // ==== build grid ====
  for (var i = 0; i < nbX * nbY; i++) {
    grid[i] = new Cell()
  }
  // ==== create image particle ====
  var r1 = rad * 0.5
  var r2 = r1 * 0.25
  var particleImage = document.createElement('canvas')
  particleImage.width = r1 * 30
  particleImage.height = r1 * 30
  var ict = particleImage.getContext('2d')
  ict.shadowBlur = rad
  ict.shadowColor = 'rgb(0,240,236)'
  ict.beginPath()
  ict.arc(r1, r1, r2, 0, 2 * Math.PI)
  ict.fillStyle = 'rgba(0,240,236,1)'
  ict.fill()
  ict.strokeStyle = 'rgb(0,240,236)'

  ict.stroke()
  ict.closePath()

  function pass1(n) {
    var px = particles_x[n]
    var py = particles_y[n]
    // ==== maintain spatial hashing grid ====
    var g =
      grid[
        ((0.5 + py / gridResolution) | 0) * nbX +
          ((0.5 + px / gridResolution) | 0)
      ]
    g.neighborsParticles[g.len++] = n
    // ==== mouse pressed ====
    if (pointer.active) {
      var vx = px - pointer.xp
      var vy = py - pointer.yp
      var vlen = Math.sqrt(vx * vx + vy * vy)
      if (vlen >= 1 && vlen < 80) {
        particles_velx[n] += (0.5 * rad * (vx / vlen)) / vlen
        particles_vely[n] += (0.5 * rad * (vy / vlen)) / vlen
      }
    }
    // ==== apply gravity ====
    particles_vely[n] += 0.05
    // ==== save previous position ====
    particles_pprevx[n] = px
    particles_pprevy[n] = py
    // ==== advance to predicted position ====
    particles_x[n] += particles_velx[n]
    particles_y[n] += particles_vely[n]
  }

  // ==== Double Density Relaxation Algorithm ====
  function pass2(n) {
    var px = particles_x[n]
    var py = particles_y[n]
    var pressure = 0,
      presnear = 0,
      nl = 0
    // ----- get grid position -----
    var xc = (0.5 + px / gridResolution) | 0
    var yc = (0.5 + py / gridResolution) | 0
    // ----- 3 x 3 grid cells -----
    for (var xd = -1; xd < 2; xd++) {
      for (var yd = -1; yd < 2; yd++) {
        var h = grid[(yc + yd) * nbX + (xc + xd)]
        if (h && h.len) {
          // ==== for each neighbours pair ====
          for (var a = 0, l = h.len; a < l; a++) {
            var pn = h.neighborsParticles[a]
            if (pn != n) {
              var vx = particles_x[pn] - px
              var vy = particles_y[pn] - py
              var vlen = Math.sqrt(vx * vx + vy * vy)
              if (vlen < rad) {
                // ==== compute density and near-density ====
                var q = 1 - vlen / rad
                pressure += q * q // quadratic spike
                presnear += q * q * q // cubic spike
                particles_q[pn] = q
                particles_vxl[pn] = (vx / vlen) * q
                particles_vyl[pn] = (vy / vlen) * q
                neighbors[nl++] = pn
              }
            }
          }
        }
      }
    }
    // ==== screen limits ====
    if (px < r2) {
      q = 1 - Math.abs(px / r2)
      particles_x[n] += q * q * 0.5
    } else if (px > screen.width - r2) {
      q = 1 - Math.abs((screen.width - px) / r2)
      particles_x[n] -= q * q * 0.5
    }
    if (py < r2) {
      q = 1 - Math.abs(py / r2)
      particles_y[n] += q * q * 0.5
    } else if (py > screen.height - r2) {
      q = 1 - Math.abs((screen.height - py) / r2)
      particles_y[n] -= q * q * 0.5
    }
    if (px < r2) particles_x[n] = r2
    else if (px > screen.width - r2) particles_x[n] = screen.width - r2
    if (py < r2) particles_y[n] = r2
    else if (py > screen.height - r2) particles_y[n] = screen.height - r2
    // ==== second pass of the relaxation ====
    pressure = (pressure - 3) * 1
    presnear *= 0.5
    for (a = 0; a < nl; a++) {
      var np = neighbors[a]
      // ==== apply displacements ====
      var p = pressure + presnear * particles_q[np]
      var dx = particles_vxl[np] * p * 0.5
      var dy = particles_vyl[np] * p * 0.5
      particles_x[np] += dx
      particles_y[np] += dy
      particles_x[n] -= dx
      particles_y[n] -= dy
    }
  }

  function pass3(n) {
    // ==== use previous position to compute next velocity ====
    particles_velx[n] = particles_x[n] - particles_pprevx[n]
    particles_vely[n] = particles_y[n] - particles_pprevy[n]
    // ==== draw particle ====
    ctx.drawImage(particleImage, particles_x[n] - r1, particles_y[n] - r1)
  }

  // ==== main loop ====
  function run() {
    var i, l
    requestAnimationFrame(run)
    // ==== inject new particles ====
    if (nParticles < maxParticles) {
      particles_x[nParticles] = 0.1 * screen.width + Math.random()
      particles_y[nParticles] = 0.3 * screen.height + Math.random()
      nParticles++
    }
    // ==== clear screen ====
    ctx.clearRect(0, 0, screen.width, screen.height)
    // ==== reset grid ====
    for (i = 0, l = nbX * nbY; i < l; i++) grid[i].len = 0
    // ==== simulation passes ====
    for (i = 0; i < nParticles; i++) pass1(i)
    for (i = 0; i < nParticles; i++) pass2(i)
    for (i = 0; i < nParticles; i++) pass3(i)
  }

  // ==== start animation ====
  requestAnimationFrame(run)
})()
